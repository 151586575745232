import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from 'gatsby-link'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCalendar } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'

const NewsSection = ({ news }) => (
  <StaticQuery
    query={graphql`
      query NewsSectionQuery {
        news: allContentfulNews(
          filter: {
            publishTo: {
              elemMatch: { url: { eq: "https://www.cics.com" } }
            }
          }
          sort: { fields: date, order: DESC }
          limit: 4
        ) {
          edges {
            node {
              id
              slug
              title
              date
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData( width: 600, height: 300, quality: 60 )
                  }
                }
              }
            }
          }
        }
        newspaper: file(name: { eq: "newspaper" }) {
          childImageSharp {
            gatsbyImageData(quality: 60) 
          }
        }
      }
    `}
    render={data => (
      <section className="news-featured-area bg-blue-gradient">
        <div className="square-box box-one"></div>
        <div className="square-box box-two"></div>

        <MDBContainer>
          <MDBRow>
            <MDBCol lg="5">
              <MDBAnimation reveal type="fadeInLeft" duration="1587ms">
                <div className="mt-3 pr-5">
                  <h2 className="font-alt title-xs-large title-extra-large text-white">
                    News from <span className="font-w-700">CICS.com</span> and <span className="font-w-700">UNICOM Global</span>
                  </h2>
                  <p className="mt-4 mb-4 font-alt font-w-400 text-large text-white">
                    Visit our dedicated news page for top stories and the latest headlines.
                  </p>
                  <div className="d-none d-sm-block mb-3">
                    <Link
                      to="/news/"
                      className="nav-link btn btn-mdb-color mr-0 mt-xl-3"
                      style={{ backgroundColor: 'rgb(41,83,149)', color: '#fff', }}
                    >
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                      More News
                    </Link>
                  </div>
                </div>
              </MDBAnimation>
            </MDBCol>

            <MDBCol lg="7">
              <MDBAnimation reveal type="fadeInUp" duration="1587ms">
                <MDBRow>
                  {data.news.edges.map(({ node }) => (
                    <MDBCol lg="6" md="6" className="d-flex pb-card-home-news" key={node.id}>
                      <div className="card mb-4 flex-fill">
                        <div className="view-news overlay rounded-top drop-shadow" style={{ maxHeight: '145px' }} >
                          <Link to={`/news/${node.slug}/`}>
                          <GatsbyImage
                              image={node.image ? node.image.localFile.childImageSharp.gatsbyImageData : data.newspaper.childImageSharp.gatsbyImageData}
                              className="img-fluid"
                              alt={node.title}
                            />
                          </Link>
                        </div>
                        <Link to={`/news/${node.slug}/`} className="btn-floating btn-action ml-auto mr-4 bg-ug-yellow" >
                          <FontAwesomeIcon icon={faChevronRight} className="pl-1" />
                          Article
                        </Link>
                        <div className="card-body">
                          <Link to={`/news/${node.slug}/`} style={{ color: '#000' }} >
                            <p className="font-w-400 text-medium pt-2 ellipsis"> {node.title} </p>
                          </Link>
                        </div>
                        <div className="rounded-bottom bg-ug-yellow text-center pt-3 bg-card-btm">
                          <ul className="list-unstyled list-inline font-w-400 letter-spacing-2 text-small mb-3">
                            <li className="list-inline-item pr-2 white-text">
                              <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                              {moment(node.date).format('YYYY/MM/DD')}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </MDBCol>
                  ))}

                  <MDBCol lg="12" md="12" className="text-center d-block d-sm-none d-md-none d-xl-none" >
                    <Link to="/news/" className="btn Ripple-parent btn-mdb-color mr-0 mt-xl-3">
                      <FontAwesomeIcon icon={faChevronRight} className="mr-2" />{' '}
                      Read
                    </Link>
                  </MDBCol>
                </MDBRow>
              </MDBAnimation>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )}
  />
)
export default NewsSection